<template>
    <div class="w-100">
        <form @submit.prevent="validForm" class="row">
            <div class="col-6">
                <input type="text" placeholder="Clef de la licence" class="form-control" v-model="user_licence" required>
            </div>
            <div class="col-6 text-center">
                <button class="btn btn-primary">
                    <div class="d-inline">
                        Rechercher
                    </div>
                    <div class="d-none">
                        <font-awesome-icon class="spinner_home" :icon='["far", "spinner"]' spin />
                    </div>
                </button>
            </div>
        </form>
        <div class="box mt-3" v-if="lastSynchro">
            Dernière synchronisation : {{lastSynchro}}
        </div>
        <div class="box mt-3" v-if="userDevice">
            Plateforme de l'utilisateur : {{userDevice}}
        </div>
        <div class="box mt-3" v-if="licenceNbHorse">
            Nombre total de chevaux de la licence : {{licenceNbHorse}}
        </div>
    </div>
</template>


<script type="text/javascript">
import { EventBus } from 'EventBus';
import StatistiquesMixin from "@/mixins/Statistiques.js";
import Navigation from "@/mixins/Navigation.js";

export default {
    name: "UserStats",
    mixins: [StatistiquesMixin, Navigation],
    data () {
        return {
            lastSynchro: '',
            userDevice : '',
            licenceNbHorse: '',
            user_licence: '',
            events_tab: {
                'UserStats::autoCompleteInput': this.autoCompleteInput
            }
        }
    },

    methods: {
        async validForm() {
            this.lastSynchro = null
            this.userDevice = null
            this.licenceNbHorse = null

            this.$el.childNodes[0][1].childNodes[0].className = "d-none"
            this.$el.childNodes[0][1].childNodes[1].className = "d-inline"

            const getLastSynchro = await this.getLastSynchro(this.user_licence)
            .catch(e => {
                console.log("Erreur sur getLastSynchro", e)
            })
            let formatLastSynchro
            if (getLastSynchro) {
                let date = new Date(getLastSynchro)
                formatLastSynchro = ('0' + date.getDate()).slice(-2)+"/"+('0' + (date.getMonth()+1)).slice(-2)+"/"+date.getFullYear()
            }
            this.lastSynchro = formatLastSynchro

            const getUserDevice = await this.getUserDevice(this.user_licence)
            .catch(e => {
                console.log("Erreur sur getUserDevice", e)
            })
            this.userDevice = getUserDevice

            const getLicenceNbHorse = await this.getLicenceNbHorse(this.user_licence)
            .catch(e => {
                console.log("Erreur sur getLicenceNbHorse", e)
            })
            this.licenceNbHorse = getLicenceNbHorse

            this.$el.childNodes[0][1].childNodes[0].className = "d-inline"
            this.$el.childNodes[0][1].childNodes[1].className = "d-none"
        },
        autoCompleteInput(licence_key) {
            this.user_licence = licence_key
            this.validForm()
        }
    },
}
</script>
